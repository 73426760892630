.contact {
    width: 100%;
    height: 90vh;
    display: flex;
  }
  
  .contact .leftSide {
    height: 100%;
    flex: 40%;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.63), rgba(0, 0, 0, 0.623)); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* opacity: 0.8; */
    /* filter: blur(4px) contrast(1.25);  */
  }

  .contact .leftSide::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 100vh;
    backdrop-filter: blur(5px);
}
  
  .contact .rightSide {
    height: 100%;
    flex: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    display: flex;
    flex-direction: column;   
    padding: 12px 12px; 
    color: #bec9d3;
    background: rgba(21,23,24,0.75);
    border: 0px solid #393e41;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,0.06) 0px 0px 8px, rgba(0,0,0,0.08) 0px 1px 3px;
    box-sizing: border-box;
    border-color: rgba(0, 0, 0, 0.2);  
  }
  
  .contact .rightSide h1 {    
    font-weight: 10;
    font-size: 50px;
    margin-left: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 30px;
  }
  
  form input {
    height: 40px;
    width: 80%;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #121619;
    border-radius: 5px;
    color: black;
    background-color: #ffffffc9;
  }
  
  form textarea {
    margin-top: 15px;
    height: 70px;
    width: 80%;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #121619;
    border-radius: 5px;
    background-color: #ffffffc9;    
  }

  input::placeholder,
  textarea::placeholder {   
    
    color: black;
    font-family: Arial, Helvetica, sans-serif;
  }
  form input:focus,
  textarea:focus {
    outline: none;
  }
  
  form label {
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #bec9d3;
  }
  
  form button {
    margin-top: 40px;
    width: 140px;
    height: 40px;
    border: none;    
    background-color: #bec9d3;
    color: #121619;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
  }

  form button:disabled {     
    background-color: #bec9d380;
    color: #12161975;
  }