.App {
  width: 100%;
  height: 100vh;  
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;   
  background: linear-gradient(to bottom, rgba(3, 3, 3, 0), #030303);
  background-attachment: fixed;  
}

html{
  
  height: 100%;  
}

body{
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-color: #030303; 
}

.App::after{
  content: "";    
  background: url("./assets/Mountains.jpg") no-repeat fixed top;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;   
  background-attachment: local, scroll;
  width: 100%;
  height: 100%; 
  position: absolute;
  z-index: -1;
  filter: blur(8px);
  /* transform: scale(1.1);  */
  
}
