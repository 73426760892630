.product{
    padding: 20px;
    padding-top: 120px;
}

.product .content{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;   
    padding: 12px 12px; 
    color: #bec9d3;
    background: rgba(21,23,24,0.75);
    border: 0px solid #393e41;
    border-radius: 6px;
    box-shadow: rgba(0,0,0,0.06) 0px 0px 8px, rgba(0,0,0,0.08) 0px 1px 3px;
    box-sizing: border-box;
    border-color: rgba(0, 0, 0, 0.2);  
}

.product .content .tab-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    margin: 0 auto;
    padding: 6px;
    text-align: center;
}

.product .content .tab-group .tab{  
    display: inline-block;
    padding: 10px 20px;
    color: #bec9d3;
    
    text-decoration: none;     
    font-size: 15px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px; 
    cursor: pointer;
    transition: background-color 0.1s ease;
}

.product .content .tab-group .tab:hover{
    background-color: #90EE90;
    color: black;
}


@media only screen and (min-width: 651px)
{
    .product {
        margin: 0 auto; 
        width: calc(100% -(10px* 8));
        max-width: 1400px;
    }
}