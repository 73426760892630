.about{
  padding: 20px;
  padding-top: 120px;
}

.about .top {
  width: 100%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  backdrop-filter: blur(5px);
}

.about .bottom {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  margin-top: 50px;
  padding: 12px 12px; 
  color: #bec9d3;
  background: rgba(21,23,24,0.75);
  border: 0px solid #393e41;
  border-radius: 6px;
  box-shadow: rgba(0,0,0,0.06) 0px 0px 8px, rgba(0,0,0,0.08) 0px 1px 3px;
  box-sizing: border-box;
  border-color: rgba(0, 0, 0, 0.2);  
}

.about .bottom p {
  width: 1200px;
}

@media only screen and (min-width: 651px)
{
  .about {
      margin: 0 auto; 
      width: calc(100% -(10px* 8));
      max-width: 1400px;
  }
}