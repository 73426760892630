.footer {
    width: 100%;
    height: 60px;
    position:absolute;
    left:0;
    bottom:0;
    right:0;
    background-color: #121619;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }