.hamburger-dropdown {
    position: relative;    
    display: inline-block;
  }
  
  .hamburger-dropdown-content {    
    display:block;
    position:absolute;
    top: 100%;
    /*top: 100%; /* Position the dropdown content below the dropdown button */
    /*left: 0; /* Align the dropdown content with the left edge of the dropdown button */
    right: 0%;
    min-width: 200px;
    background-color: rgb(53, 53, 53);  
    border-radius: 5px;    
    z-index: 20;
    padding-top: 10px;
}

.hamburger-dropdown-content li {
    list-style-type: none;        
    min-height: 30px;
    display:list-item;
    flex-direction: column;
    align-items: center;
}
