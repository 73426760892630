/* Dropdown.css */

.dropdown {
    position: relative;    
    display: inline-block;
}

.dropdown-content {    
    display:block;
    position:absolute;
    top: calc(100% + 5px);
    /*top: 100%; /* Position the dropdown content below the dropdown button */
    /*left: 0%; /* Align the dropdown content with the left edge of the dropdown button */
    min-width: 200px;
    background-color: rgb(53, 53, 53);  
    border-radius: 5px;    
    z-index: 20;
    padding-top: 10px;
}

.dropdown-content li {
    list-style-type: none;        
    min-height: 30px;
    display:list-item;
    flex-direction: column;
    align-items: center;
}

.dropdown-content a {
    color: white;    
    text-decoration: none;
}

@media only screen and (max-width: 770px) {
    .dropdown-content {
      display: none;
    }  
  }