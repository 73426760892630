.navbar {
    width: 100%;
    height: 100px;
    background-color: #121619;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 10;
  }
  
  .navbar .logo {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;    
    justify-content: center;
  }
  
  .navbar .logo img {    
    width: auto;
    max-width: 300px;    
    height: 40px;
  }
  
  .navbar .menu {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
  }

  .navbar a:hover{
    color: #90EE90;
  }

  .navbar .menu .dropdownButton {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;    
    text-decoration: none;
    margin: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .navbar .navbutton {    
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    text-decoration: none;
    margin: 10px;     
    font-family: Arial, Helvetica, sans-serif;
    align-self: center;
  }
  
  .navbar .navbutton svg {
    font-size: 40px;
  }  
  
  @media only screen and (max-width: 900px) {
    .navbar .menu a {
      width: 70px;
    }
    .navbar .logo {
      padding-left: 50px;
    }
  }
  
  @media only screen and (max-width: 770px) {
    .navbar .menu a {
      display: none;
    }
  
    .navbar .menu {
      justify-content: flex-end;
      padding-right: 50px;
    }
  
    .navbar .navbutton {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 770px) {
    .navbar .navbutton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  }